<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <b-row>
      <b-col>
        <div class="todo-app-list">

          <!-- App Searchbar Header -->
          <div class="app-fixed-search d-flex align-items-center">

            <!-- Toggler -->
            <div class="sidebar-toggle d-block d-lg-none ml-1">
              <feather-icon
                icon="MenuIcon"
                size="21"
                class="cursor-pointer"
                @click="mqShallShowLeftSidebar = true"
              />
            </div>

            <!-- Searchbar -->
            <div class="d-flex align-content-center justify-content-between w-100">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-prepend>
                <b-form-input
                  :value="searchQuery"
                  placeholder="Search for Chatter"
                  @input="updateRouteQuery"
                />
              </b-input-group>
            </div>

            <!-- Dropdown -->
            <div class="dropdown">
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0 mr-1"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="resetSortAndNavigate">
                  Reset Sort
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }">
                  Sort A-Z
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-desc' } }">
                  Sort Z-A
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
                  Sort Assignee
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'due-date' } }">
                  Sort Due Date
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>

          <!-- Todo List -->
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="todo-task-list-wrapper list-group scroll-area"
            style="height: 80vh"
          >
            <draggable
              v-model="tasks"
              handle=".draggable-task-handle"
              tag="ul"
              class="todo-task-list media-list"
            >
              <!--              Change this to tasks-->
              <li
                v-for="chatter in chatterList"
                :key="chatter.id"
                class="todo-item"
                :class="{'selected': chatter.isSelected }"
                @click="updateTaskIsSelected(chatter)"
                @dblclick="test2(chatter)"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="draggable-task-handle d-inline"
                />
                <div class="todo-title-wrapper">
                  <div class="todo-title-area">
                    <div class="title-wrapper">
                      <b-form-checkbox
                        :checked="chatter.isSelected"
                        @click.native.stop
                        @change="updateTaskIsCompleted(chatter)"
                      />
                      <span class="todo-title">{{ chatter.username }}</span>
                    </div>
                  </div>
                  <div class="todo-item-action">
                    <div class="badge-wrapper mr-1">
                      <b-badge
                        v-for="tag in chatter.tags"
                        :key="tag"
                        pill
                        :variant="`light-${resolveTagVariant(tag)}`"
                        class="text-capitalize"
                      >
                        {{ tag }}
                      </b-badge>
                    </div>
                    <small class="text-nowrap text-muted mr-1">{{ formatDate(chatter.dueDate, { month: 'short', day: 'numeric'}) }}</small>
                    <b-avatar
                      size="32"
                      :src="chatter.avatarUrl"
                    />
                  </div>
                </div>

              </li>
            </draggable>
            <div
              class="no-results"
              :class="{'show': !tasks.length}"
            >
              <h5>No Items Found</h5>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </b-col>
      <b-col style="height: 100vh">
        <!--        <vue-perfect-scrollbar-->
        <!--          ref="refChatLogPS"-->
        <!--          :settings="perfectScrollbarSettings"-->
        <!--          class="user-chats scroll-area"-->
        <!--        >-->
        <!--          <chat-log-->
        <!--            :chat-data="selectedUser1"-->
        <!--          />-->
        <!--        </vue-perfect-scrollbar>-->
        <!--        <b-form-->
        <!--            class="chat-app-form"-->
        <!--            @submit.prevent="sendMsg"-->
        <!--        >-->
        <!--          <b-input-group class="input-group-merge form-send-message mr-1">-->
        <!--            <b-form-input-->
        <!--                v-model="chatInputMessage"-->
        <!--                placeholder="Enter your message"-->
        <!--            />-->
        <!--          </b-input-group>-->
        <!--          <b-button-->
        <!--              variant="primary"-->
        <!--              type="submit"-->
        <!--          >-->
        <!--            Send-->
        <!--          </b-button>-->
        <!--        </b-form>-->
        <Chat />

      </b-col>

    </b-row>

    <!-- Task Handler -->
    <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :clear-task-data="clearTaskData"
      @remove-task="removeTask"
      @add-task="addTask"
      @update-task="updateTask"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar
        :task-tags="taskTags"
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import store from '@/store'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox, BBadge, BAvatar, BRow,
  BCol, BForm, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { getUserData } from '@/auth/utils'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import todoStoreModule from './todoStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import ChatLog from '../chat/ChatLog.vue'
import Chat from '../chat/Chat.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    BForm,
    BButton,
    draggable,
    VuePerfectScrollbar,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
    ChatLog,
    Chat,

  },
  data() {
    return {
      activeUser: { id: 12, username: 'Test' },
      joinedChat: false,
      selectedUsers: [],
      selectedUser: { },
      chatInputMessage: '',
      chatterList: [],

    }
  },
  created() {
    this.fetchTasksVue()
  },
  methods: {
    sendMsg() {
      console.log(this.activeChat)
      console.log(this.activeChat.value)
      const msgGroup = {
        senderId: 'GonnaBeTheGeminosName',
        senderName: 'GonnaBeTheGeminosName',
        fromGeminos: true,
        messages: [{
          received: true,
          fromGeminos: true,
          msg: this.chatInputMessage,
          time: new Date().getTime(),
        }],
      }
      this.$store.state.chat.chatLog.push(msgGroup)
    },
    fetchTasksVue() {
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc(userData.id).collection('Chatter').doc('chatter')
        .get()
        .then(async snap => {
          console.log(snap.data())
          this.chatterList = []
          await snap.data().chatter.forEach(chatter => {
            const newChatter = {
              avatarUrl: chatter.avatarUrl,
              isMarkedAsNotWorking: chatter.isMarkedAsNotWorking,
              isSelected: chatter.isSelected,
              isViewlist: chatter.isViewlist,
              uid: chatter.uid,
              isDisabled: chatter.isDisabled,
              username: chatter.username,
              tags: {
                ...(chatter.hasAvatar && { hasAvatar: 'Picture' }),
                ...(chatter.canFollow && { canFollow: 'canFollow' }),
                ...(chatter.isBadge && { isBadge: 'Badge' }),
                ...(chatter.isPrime && { isPrime: 'Prime' }),
                ...(chatter.isOwn && { isOwn: 'true' }),
              },
            }
            this.chatterList.push(newChatter)
          })
          console.log(this.chatterList.length)
        })
    },
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasks()
    })

    const tasks = ref([])
    const selectedUser1 = ref({})

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-date',
    ]
    const sortBy = ref(routeSortBy.value)
    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) sortBy.value = val
      else sortBy.value = val
    })
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {})
    }

    const blankTask = {
      id: null,
      title: '',
      dueDate: new Date(),
      description: '',
      assignee: null,
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const addTask = val => {
      store.dispatch('app-todo/addTask', val)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
    }
    const removeTask = () => {
      store.dispatch('app-todo/removeTask', { id: task.value.id })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
    }
    const updateTask = taskData => {
      store.dispatch('app-todo/updateTask', { task: taskData })
        .then(() => {
          console.log('Tess st123')
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        }).catch(e => console.log(e))
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 500,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const taskTags = [
      { title: 'Prime', color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'prime' } } },
      { title: 'Badge', color: 'success', route: { name: 'apps-todo-tag', params: { tag: 'badge' } } },
      { title: 'Normal', color: 'warning', route: { name: 'apps-todo-tag', params: { tag: 'normal' } } },
      { title: 'Selected', color: 'info', route: { name: 'apps-todo-tag', params: { tag: 'selected' } } },

    ]

    const resolveTagVariant = tag => {
      if (tag === 'Prime') return 'primary'
      if (tag === 'Badge') return 'success'
      if (tag === 'normal') return 'warning'
      if (tag === 'high') return 'danger'
      if (tag === 'selected') return 'info'
      return 'primary'
    }

    const resolveAvatarVariant = tags => {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    }

    // Search Query
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTasks())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const fetchTasks = () => {
      store.dispatch('app-todo/fetchTasks', {
        q: searchQuery.value,
        filter: router.currentRoute.params.filter,
        tag: router.currentRoute.params.tag,
        sortBy: sortBy.value,
      })
        .then(response => {
          tasks.value = response.data
          console.log(tasks.value)
        })
    }

    fetchTasks()

    const handleTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }
    const test2 = taskData => {
      console.log(taskData)
    }
    // Single Task isCompleted update
    const updateTaskIsCompleted = taskData => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted
      updateTask(taskData)
    }

    const updateTaskIsSelected = taskData => {
      console.log(taskData)
      // eslint-disable-next-line no-param-reassign
      taskData.isSelected = !taskData.isSelected
      selectedUser1.value = taskData
      // if (taskData.isSelected) this.selectedUser = taskData
      updateTask(taskData)
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      selectedUser1,
      test2,
      task,
      updateTaskIsSelected,
      tasks,
      removeTask,
      addTask,
      updateTask,
      clearTaskData,
      taskTags,
      searchQuery,
      fetchTasks,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
