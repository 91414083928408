<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
            >
              Add own Accounts
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              block
              @click="fillAccounts()"
            >
              (Re-)Fill Accounts
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Tags
              </h6>
              <feather-icon icon="PlusIcon" />
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in taskTags"
                :key="tag.title + $route.path"
                :to="tag.route"
                :active="isDynamicRouteActive(tag.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${tag.color}`"
                />
                <span>{{ tag.title }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
    taskTags: {
      type: Array,
      required: true,
    },
  },
  methods: {
    setChatterObject({
      // eslint-disable-next-line no-unused-vars
      badge = false, badgeName = '', createDate, description = false, id = '', imageUrl = '', oauth = '', picture = false, prime = false, username = '',
    } = {}) {
      const newChatter = {
        avatarUrl: imageUrl,
        canFollow: false,
        hasBio: description,
        isBadge: badge,
        isDisabled: false,
        isFollowed: false,
        isMarkedAsNotWorking: false,
        isPrime: prime,
        isSelected: true,
        isViewlist: true,
        uid: id,
        username,
      }
      return newChatter
    },
    async fillAccounts() {
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      const doc = await sx.collection('System').doc('viewList').get()
      const viewList = await doc.data().workingList
      sx.collection('User')
        .doc(userData.id)
        .get()
        .then(async result => {
          const userDoc = await result.data()
          const count = userDoc.License.ChatterCount
          const chatters = []
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < count; i++) {
            const chatterFromlist = viewList[i]
            const newChatter = this.setChatterObject(chatterFromlist)
            if (!(newChatter.avatarUrl === undefined))chatters.push(newChatter)
          }
          console.log(chatters)
          sx.collection('User')
            .doc(userData.id)
            .collection('Chatter')
            .doc('chatter')
            .update({ chatter: chatters })
            .then(result2 => {
              console.log(result2)
            })
        })

      // const refillAccount = this.$firebase.functions().httpsCallable('refillChatterForAccount')
      // refillAccount({}).then(result => {
      //   console.log(result)
      // })

      console.log('Refill')
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const taskFilters = [
      { title: 'All', icon: 'StarIcon', route: { name: 'apps-todo' } },
      { title: 'Following', icon: 'HeartIcon', route: { name: 'apps-todo-filter', params: { filter: 'important' } } },
      { title: 'NotFollowing', icon: 'ArchiveIcon', route: { name: 'apps-todo-filter', params: { filter: 'completed' } } },
      { title: 'Disabled', icon: 'TrashIcon', route: { name: 'apps-todo-filter', params: { filter: 'deleted' } } },
    ]

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>
